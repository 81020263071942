<script>
import { required } from "vuelidate/lib/validators";
import Tags from "@/service/prod-tags"
import UserServiceApi  from "@/service/api/users"

export default {
  props: ["userData"],
  data() {
    return {
      userEditForm: {
        name: "",
        lastName: "",
        image_path:"",
        sizePref:"",
        apparelSize:"",
        verification:"",

      },
        apparelSizeOpt: Tags["Streetwear"],
        sizePrefOpt: Tags["Sneakers"],
        updating: false
    };
  },
  mounted() {
    if (this.userData) {
      this.parseUserForm(this.userData);
    }
  },
  validations: {
    userEditForm: {
      name: { required },
    },
  },
  methods: {
    updateUser() {
      let form = new FormData();
      form.append("user_id",this.userData.id)
      form.append("action","edit")
      form.append("name",this.userEditForm.name)
      form.append("lastName",this.userEditForm.lastName)
      if(this.userEditForm.image_path && this.userEditForm.image_path.files[0])
        form.append("image_path",this.userEditForm.image_path.files[0].file)
      form.append("sizePref",this.userEditForm.sizePref)
      form.append("apparelSize",this.userEditForm.apparelSize)
      form.append("verification",this.userEditForm.verification?1:0)
      this.updating = true;
      UserServiceApi.updateUser(form).then((response)=>{
        if(response.data.success){
            this.ToastMessage({title:"User update",body:"User data updated"})
            this.$emit("userUpdated")
        }
        else{
            this.ToastMessage({title:"User update",body:"User data updation failed"},"danger")
        }
            
      }).catch(()=>{
           this.ToastMessage({title:"User update",body:"User data updation failed"},"danger")
      }).finally(()=>
          this.updating = false
        );
    },
    parseUserForm(data) {
      this.userEditForm = {
        name: data.name,
        lastName: data.lastName,
        verification: data.verification === "1" ? true : false,
        sizePref:data.sizePref,
        apparelSize:data.apparelSize,
      };
    },
  },
  watch: {
    userData(val) {
      this.parseUserForm(val);
    },
  },
};
</script>

<template>
  <div>
    <div class="card">
      <div class="card-body m-5" style="padding-bottom:100px;">
        <h4 class="card-title my-4">Edit User</h4>
        <form class="needs-validation" @submit.prevent>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="validationCustom02">First Name</label>
                <input
                  type="text"
                  class="form-control user-edit-input"
                  v-model="userEditForm.name"
                  placeholder="First Name Here!"
                />
                <div class="invalid-feedback">
                  <span>First name is required.</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="validationCustom02">Last Name</label>
                <input
                  type="text"
                  v-model="userEditForm.lastName"
                  class="form-control user-edit-input"
                  placeholder="Last Name Here!"
                />
              </div>
            </div>
          </div>
      
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="validationCustom02">Size Pref</label>
                 <b-form-select
                  v-model="userEditForm.sizePref"
                  :options="sizePrefOpt"
                  class="form-control user-edit-input"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="validationCustom02">Apparel Size</label>
                 <b-form-select
                  :options="apparelSizeOpt"
                  class="form-control user-edit-input"
                  v-model="userEditForm.apparelSize"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label>Verification</label>
                <b-form-checkbox
                  name="check-button"
                  v-model="userEditForm.verification"
                  switch
                  
                >
                </b-form-checkbox>
              </div>
            </div>
          </div>
          <FormulateInput
            type="image"
            name="headshot"
            v-model="userEditForm.image_path"
            label="Change user profile pic"
            help="Select a png, jpg or gif to upload."
            validation="mime:image/jpeg,image/png,image/gif"
          />
  
          <div class="mt-4 text-center my-4">
            <button
              style="margin-top:50px"
              class="btn btn-warning w-md waves-effect waves-light float-right"
              type="button"
              @click="updateUser()"
            >
             <span v-if="this.updating">Updating...</span> <span v-else>Update</span> 
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style>
.user-edit-input {
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
}
.user-edit-input:active,
.user-edit-input:focus {
  border: 1px solid #f1f1f1;
  background-color: #f1f1f1;
}
</style>
